exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-grow-tsx": () => import("./../../../src/pages/grow.tsx" /* webpackChunkName: "component---src-pages-grow-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-tailormade-tsx": () => import("./../../../src/pages/tailormade.tsx" /* webpackChunkName: "component---src-pages-tailormade-tsx" */),
  "component---src-pages-today-tomorrow-healthtech-tsx": () => import("./../../../src/pages/today-tomorrow-healthtech.tsx" /* webpackChunkName: "component---src-pages-today-tomorrow-healthtech-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-expertise-tsx": () => import("./../../../src/templates/expertise.tsx" /* webpackChunkName: "component---src-templates-expertise-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-work-post-tsx": () => import("./../../../src/templates/work-post.tsx" /* webpackChunkName: "component---src-templates-work-post-tsx" */)
}

